import React, { useContext, useState } from "react";
import BreadCrumb from "../utils/BreadCrumb";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import Slider from "react-infinite-logo-slider";
const Contact = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <BreadCrumb title={t.contact} />
      <Helmet>
        <title>{t.contact}</title>
      </Helmet>
      <div>
        <section className="contact-section pt-80 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 mb-30">
                <div className="contact-page-side-content">
                  <h3 className="contact-page-title">{t.contact}</h3>
                  <div className="single-contact-block">
                    <h4>
                      <i className="fa fa-fax me-2" /> {t.address}
                    </h4>
                    <p>{t.contactAddress}</p>
                  </div>
                  <div className="single-contact-block">
                    <h4>
                      <i className="fa fa-phone me-2" /> {t.phone}
                    </h4>
                    <p>
                      <a href="tel:+994508055511">
                        {t.phone}: +994 50 805 55 11
                      </a>
                    </p>
                  </div>
                  <div className="single-contact-block">
                    <h4>
                      <i className="fas fa-envelope me-2" /> {t.email}
                    </h4>
                    <p>
                      <a href="mailto:info@myperfume.az">info@myperfume.az</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 mb-30">
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10217.876961402166!2d49.88486534869194!3d40.41343636586063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403063f2367dfdb3%3A0xce6752a9956f76dd!2sChinar%20Plaza!5e0!3m2!1saz!2saz!4v1728366760224!5m2!1saz!2saz"
                    width={600}
                    height={100}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;


// import React, { useState } from "react";


//   // Her bir input için state oluşturuluyor
//   const [values, setValues] = useState({
//     num1: "",
//     num2: "",
//     num3: "",
//     num4: "",
//     num5: "",
//     num6: "",
//     num7: "",
//   });

//   // Sayı tuşlarına izin veren fonksiyon
//   const isNumberKey = (event) => {
//     const charCode = event.charCode;
//     return charCode >= 48 && charCode <= 57; // Sadece 0-9 tuşlarına izin verir
//   };

//   // Giriş değerini güncelleme fonksiyonu
//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     if (value === "" || /^[0-9]$/.test(value)) {
//       setValues((prevValues) => ({
//         ...prevValues,
//         [name]: value, // Her bir input için state güncellenir
//       }));
//     }
//   };

//   // Sonraki inputa odaklanma işlemi
//   const focusNext = (event) => {
//     const { name } = event.target;
//     const nextInput = document.querySelector(`#${name.replace(/[0-9]/g, (match) => parseInt(match) + 1)}`);
//     if (nextInput) {
//       nextInput.focus();
//     }
//   };

//   // Bir input alanındaki değeri silerken (backspace) otomatik olarak önceki input'a odaklanma
//   const handleKeyUp = (event) => {
//     const { name, value } = event.target;
//     if (value === "") {
//       const prevInput = document.querySelector(`#${name.replace(/[0-9]/g, (match) => parseInt(match) - 1)}`);
//       if (prevInput) {
//         prevInput.focus();
//       }
//     } else {
//       const nextInput = document.querySelector(`#${name.replace(/[0-9]/g, (match) => parseInt(match) + 1)}`);
//       if (nextInput) {
//         nextInput.focus();
//       }
//     }
//   };

//   // Girilen numarayı birleştirip filtreleme işlemi
//   const handleSearch = () => {
//     // Girilen numarayı oluşturuyoruz
//     const searchNumber = `${values.num1}${values.num2}${values.num3}${values.num4}${values.num5}${values.num6}${values.num7}`;
    
//     // Örnek veri
//     const data = [
//       { id: 1, phone: "1234567", name: "Ali" },
//       { id: 2, phone: "2345678", name: "Veli" },
//       { id: 3, phone: "3456789", name: "Ayşe" },
//       { id: 4, phone: "4567890", name: "Mehmet" },
//       { id: 5, phone: "5678901", name: "Fatma" },
//     ];

//     // Veriyi filtreliyoruz
//     const result = data.filter(item => item.phone === searchNumber);

//     // Sonucu kullanıcıya gösterme
//     if (result.length > 0) {
//       alert(`Bulundu: ${result[0].name} - ${result[0].phone}`);
//     } else {
//       alert("Numara bulunamadı.");
//     }
//   };

//   return (
//     <div>
//       <div className="inputs_list">
//         {Array.from({ length: 7 }, (_, index) => {
//           const numId = `num${index + 1}`;
//           return (
//             <input
//               key={numId}
//               id={numId}
//               name={numId}
//               type="number"
//               maxLength="1"
//               min="0"
//               max="9"
//               value={values[numId]}
//               onChange={handleChange}
//               onKeyPress={isNumberKey}
//               onKeyUp={handleKeyUp} 
//               placeholder={values[numId] === "" ? "x" : ""}
//               className={`num-input num${index + 1}`}
//             />
//           );
//         })}
//       </div>

//       <button onClick={handleSearch}>Ara</button>

//       {/* Hidden input */}
//       <input type="hidden" value="1" name="send_search" />
//     </div>
//   );
// };



