import React from "react";

const WhatSapp = () => {
  return (
    <>
    <section className="whatsapp">
      <a href="https://wa.me/994508055511" target="_blank" class="btn-whatsapp-pulse">
        <i class="fab fa-whatsapp"></i>
      </a>
    </section>
    </>
  );
};

export default WhatSapp;
