import React, { useContext, useState } from "react";
import HomeSlider from "../../pages/home/HomeSlider";
import OurProducts from "../../pages/home/OurProducts";
import NewArrivalProducts from "../../pages/home/NewArrivalProducts";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import ModalView from "../../pages/cart/ModalView";
import Discount from "../../pages/home/Discount";

const Home = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [selectedData, setSelectedData] = useState("");
  return (
    <>
      <Helmet>
        <title>MyPerfume.az | {t.original}</title>
      </Helmet>
      <HomeSlider />
      <Discount setSelectedData={setSelectedData} />
      {/* <OurProducts setSelectedData={setSelectedData} /> */}
      {/* <NewArrivalProducts setSelectedData={setSelectedData} /> */}
      <ModalView data={selectedData} />
    </>
  );
};

export default Home;
