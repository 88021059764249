import React, { useContext, useEffect, useState } from "react";
import ModalView from "./ModalView";
import BreadCrumb from "../../utils/BreadCrumb";
import { addItem } from "../../utils/addtocart";
import { addWishlist } from "../../utils/addtowishlist";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import slugify from "react-slugify";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import ApiLinkContext from "../../context/ApiLinkContext";
import axios from "axios";
import ReactPaginate from "react-paginate";
import ReactSlider from "react-slider";
import { Helmet } from "react-helmet";

import { Toaster, toast } from "sonner";
import manicon from "../../assets/img/manicon.png";
import womanicon from "../../assets/img/womanicon.jpg";
import unisexicon from "../../assets/img/unisexicon.png";

const Product = () => {
  const { productId } = useParams();
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { ApiLink } = useContext(ApiLinkContext);
  const [selectedData, setSelectedData] = useState("");
  const [loading, setLoading] = useState(true);

  const [product, setProduct] = useState([]);
  const [brand, setBrand] = useState([]);
  const [tag, setTag] = useState([]);
  const [accord, setAccord] = useState([]);

  const [change, setChange] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [volume, setVolume] = useState("");
  const [accordSelect, setAccordSelect] = useState([]);
  const [selectedSex, setSelectedSex] = useState([]);
  const [sortSelect, setSortSelect] = useState("");

  const [values, setValues] = useState([0, 0]);
  const [maxValue, setMaxValue] = useState(0);
  const [count, setCount] = useState(10);

  const [view, setView] = useState(20);
  const [search, setSearch] = useState("");
  const [searchProduct, setSearchProduct] = useState("");

  const [localWish, setLocalWish] = useState(
    () => JSON.parse(localStorage.getItem("wishlist")) || []
  );

  const location = useLocation();
  const result =
    location &&
    location.state &&
    location.state.result &&
    location.state.result;

  useEffect(() => {
    Promise.all([
      axios.get(`${ApiLink}/products.php`),
      axios.get(`${ApiLink}/brand.php`),
      axios.get(`${ApiLink}/tag.php`),
      axios.get(`${ApiLink}/accord.php`),
    ])
      .then(([productsRes, brandsRes, tagsRes, accordsRes]) => {
        const productRes = productsRes.data;
        const brandRes = brandsRes.data;
        const tagRes = tagsRes.data;
        const accordRes = accordsRes.data;
        setTag(tagRes);
        setProduct(productRes);
        setBrand(brandRes);
        setAccord(accordRes);
        const maxPrice = Math.max(...productRes.map((item) => item.price));
        setMaxValue(maxPrice);
        setValues([0, maxPrice]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const handleCheckboxChange = (id) => {
    if (change.includes(id)) {
      setChange(change.filter((brandId) => brandId !== id));
    } else {
      setChange([...change, id]);
    }
  };
  const handleAccord = (id) => {
    setAccordSelect((prev) =>
      prev.includes(id) ? prev.filter((tagId) => tagId !== id) : [...prev, id]
    );
  };
  const handleTagChange = (id) => {
    setSelectedTags((prev) =>
      prev.includes(id) ? prev.filter((tagId) => tagId !== id) : [...prev, id]
    );
  };

  const handleSexClick = (sex) => {
    if (selectedSex.includes(sex)) {
      setSelectedSex(selectedSex.filter((s) => s !== sex));
    } else {
      setSelectedSex([...selectedSex, sex]);
    }
  };

  const [slugId, type] = productId ? productId.split("-") : [null, null];
  console.log(slugId);

  let filteredData = product.filter((item) => {
    // Əgər heç bir filtr seçilməyibsə, hamısını qaytarırıq
    if (
      change.length === 0 &&
      accordSelect.length === 0 &&
      selectedTags.length === 0 &&
      volume === "all" &&
      selectedSex.length === 0 &&
      values[0] === 0 &&
      values[1] === 1000000 // Or another default price range
    ) {
      return true;
    }

    // Brend filtri
    const brandMatch = change.length === 0 || change.includes(item.brand);

    // Akkord filtri
    const accordMatch =
      accordSelect.length === 0 ||
      (item.accord &&
        item.accord.some((accordId) => accordSelect.includes(accordId)));

    // Etiket (tag) filtri
    const tagMatch =
      selectedTags.length === 0 ||
      (item.tag && item.tag.some((tagId) => selectedTags.includes(tagId)));

    // Həcmi filtri: volume === "all" allows all volumes, or filter by specific volume
    const volumeMatch =
      volume === "all" ||
      volume.length === 0 ||
      volume.includes(item.volume.toString());

    // Cinsiyyət filtri
    const sexMatch =
      selectedSex.length === 0 || selectedSex.includes(item.sex.toString());

    // Qiymət filtri
    const priceMatch = item.price >= values[0] && item.price <= values[1];

    let idMatch = true; // default olaraq true qoyuruq

    if (slugId) {
      if (slugId === item.brand.toString()) {
        idMatch = true;
      }
      else if (type === "kisi" || type === "qadin" || type === "perfumes" || type === "парфюм") {
        idMatch = item.sex && slugId === item.sex.toString();
        // idMatch = true;
      } 
      else {
        idMatch = false;
      }
    }

    // Bütün filterləri birləşdiririk və nəticəni qaytarırıq
    return (
      brandMatch &&
      tagMatch &&
      volumeMatch &&
      accordMatch &&
      sexMatch &&
      priceMatch &&
      idMatch // `idMatch` yalnız uyğun id ilə olan nəticələri qaytaracaq
    );
  });

  filteredData = filteredData.filter((item) =>{
    if (item.say > 0) {
    return  item.name.toLowerCase().includes(searchProduct.toLowerCase())
    }
  }
    
  );

  if (result && result.length > 0) {
    filteredData = filteredData.filter((item) =>
      result.some((res) => res.id === item.id)
    );
  } else {
    if (result && result.length == 0) {
      filteredData = [];
    }
  }
  if (sortSelect !== null && sortSelect == "asc") {
    filteredData.sort((a, b) => a.price - b.price);
  }
  if (sortSelect !== null && sortSelect == "desc") {
    filteredData.sort((a, b) => b.price - a.price);
  }
  if (sortSelect !== null && sortSelect == "Az") {
    filteredData.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortSelect !== null && sortSelect == "Za") {
    filteredData.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });
  }

  const ITEMS_PER_PAGE = view;
  const [currentPage, setCurrentPage] = useState(0);

  const offset = currentPage * ITEMS_PER_PAGE;
  const currentPageData = filteredData.slice(offset, offset + ITEMS_PER_PAGE);
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    window.scrollTo({ top: 100 });
  };

  const handleChange = (val) => {
    setValues(val);
  };

  const uniqueVolumes = [...new Set(product.map((item) => item.volume))];

  const uniqueSex = [...new Set(product.map((item) => item.sex))];

  const tagProductCounts = tag.map((item) => {
    const productCount = product.filter(
      (p) => p.tag && p.tag.includes(item.id)
    ).length;
    return { ...item, productCount };
  });

  const accordProductCounts = accord.map((item) => {
    const productCount = product.filter(
      (p) => p.accord && p.accord.includes(item.id)
    ).length;
    return { ...item, productCount };
  });

  const filteredTags = tagProductCounts.filter((item) =>
    item.tName.toLowerCase().includes(search.toLowerCase())
  );

  const sortedTag = filteredTags.sort(
    (a, b) => b.productCount - a.productCount
  );

  const handleCountIncrement = () => {
    setCount((prevCount) => (prevCount += 10));
  };
  useEffect(() => {
    const handleWishlistUpdate = () => {
      const fetchWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      setLocalWish(fetchWishlist);
    };

    window.addEventListener("wishUpdated", handleWishlistUpdate);

    // Cleanup
    return () => {
      window.removeEventListener("wishUpdated", handleWishlistUpdate);
    };
  }, []);
  let accordParams = product.filter(
    (item) => item.accord && item.accord.includes(slugId)
  );

  const breadCrumbName = brand.find(
    (item) => slugify(`${item.id}-${item.bName}`).toLowerCase() == productId
  );

  const [itemsToShow, setItemsToShow] = useState(12); // You can adjust the initial value

  // Handle the "Daha çox" button click
  const handleShowMore = () => {
    setItemsToShow((prev) => prev + 10); // Increase by 6 (or whatever number you prefer)
  };
  console.log(filteredData);
  return (
    <>
      <Helmet>
        <title>{t.brand.toUpperCase()}</title>
      </Helmet>
      <BreadCrumb
        title={
          productId && breadCrumbName
            ? breadCrumbName.bName
            : location && location.state && location.state.query
            ? `${t.searchResult} "${location.state.query}"`
            : slugId === "1"
            ? t.man
            : slugId === "2"
            ? t.woman
            : t.product
        }
      />

      {loading ? (
        <h2 className="text-center my-5">{t.loading}</h2>
      ) : (
        <div className="product-tab bg-white pt-80 pb-50">
          <div className="container">
            <div>
              <Toaster position="top-right" richColors />
            </div>
            <div className="row">
              <div
                className={`col-lg-${result || productId ? "12" : "9"} mb-30`}
              >
                <div className="grid-nav-wraper bg-lighten2 mb-30">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                      <nav className="shop-grid-nav">
                        <ul
                          className="nav nav-pills align-items-center"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              href="#pills-home"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              <i className="fa fa-th" />
                            </a>
                          </li>
                          <li className="nav-item mr-0">
                            <a
                              className="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              href="#pills-profile"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              <i className="fa fa-list" />
                            </a>
                          </li>
                          <li>
                            <span
                              className="total-products text-capitalize"
                              style={{ margin: "0" }}
                            >
                              {filteredData.filter((item)=>item.say > 0).length} {t.result}.
                            </span>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="col-12 col-md-6 position-relative">
                      <div className="shop-grid-button d-flex align-items-center">
                        <span className="sort-by"></span>
                        <select
                          name=""
                          id=""
                          className="form-select custom-select me-2"
                          aria-label="Default select example"
                          onChange={(e) => setView(e.target.value)}
                        >
                          <option value="" disabled selected>
                            {t.view} :
                          </option>
                          <option value="12">12</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                        </select>
                        <select
                          className="form-select custom-select"
                          aria-label="Default select example"
                          onChange={(e) => setSortSelect(e.target.value)}
                        >
                          <option selected>{t.sort}</option>
                          <option value="Az">{t.sort1}</option>
                          <option value="Za">{t.sort2}</option>
                          <option value="asc">{t.sort3}</option>
                          <option value="desc">{t.sort4}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row grid-view theme1">
                      {filteredData.length == 0 ? (
                        <h4 className="text-start">{t.notfound}</h4>
                      ) : (
                        filteredData.filter((item)=>item.say > 0).slice(0, itemsToShow).map((i) => {
                          const active = localWish.find(
                            (item) => item.id == i.id
                          );
                          const brandName = brand.find((item)=>item.id == i.brand)
                          return (
                            <div className="col-sm-6 col-lg-4 mb-30">
                              <div className="card product-card">
                                <div className="card-body">
                                  <div className="product-thumbnail position-relative">
                                    <p
                                      className={`badge badge-danger mt-2 top-center2 ${
                                        i.dis > 0 ? "d-block" : "d-none"
                                      }`}
                                    >
                                      {t.dis} 
                                    </p>
                                    <span
                                      className="badge top-right"
                                      style={{ left: "0" }}
                                    >
                                      <img
                                        src={`${
                                          i.sex == "1"
                                            ? manicon
                                            : i.sex == "2"
                                            ? womanicon
                                            : unisexicon
                                        }`}
                                        alt=""
                                        className="img-fluid"
                                        style={{
                                          position: "absolute",
                                          zIndex: "1",
                                          right: "0",
                                          width: i.sex == "1" ? "20px" : "25px",
                                        }}
                                      />
                                    </span>
                                    <NavLink
                                      to={`/${slugify(
                                        `${i.id}-${i.name}`
                                      ).toLowerCase()}`}
                                    >
                                      {i.img && i.img.length > 0 && (
                                        <>
                                          <img
                                            src={i.img[1]}
                                            alt="thumbnail"
                                            className="first-img img-fluid w-100"
                                          />
                                          <img
                                            src={i.img[0]}
                                            alt="thumbnail"
                                            className="second-img img-fluid w-100"
                                          />
                                        </>
                                      )}
                                    </NavLink>
                                    <ul className="actions d-flex justify-content-center">
                                      <li>
                                        <a
                                          className={`action ${
                                            active ? "activeWish" : ""
                                          }`}
                                          onClick={() => addWishlist(i.id)}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span
                                            title="add to wishlist"
                                            className={`icon-heart`}
                                          ></span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="action"
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#quick-view"
                                          onClick={() => {
                                            setSelectedData(i);
                                          }}
                                        >
                                          <span
                                            data-bs-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Quick view"
                                            className="icon-magnifier"
                                          />
                                        </a>
                                      </li>
                                    </ul>
                                    {/* product links end*/}
                                  </div>
                                  <div className="product-desc py-0 px-0">
                                    <h3 className="title">
                                      <Link
                                        to={`/${slugify(
                                          `${i.id}-${i.name}`
                                        ).toLowerCase()}`}
                                      >
                                     {brandName.bName} {i.name} <br /> {i.volume} {t.ml}
                                      </Link>
                                    </h3>
                                    <div className="star-rating">
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star de-selected" />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="product-price">
                                        <del
                                          className="del"
                                          style={{
                                            textDecoration: `${
                                              i.dis > 0
                                                ? "line-through"
                                                : "none"
                                            }`,
                                          }}
                                        >
                                          {i.price} ₼
                                        </del>
                                        <span
                                          className={`onsale ms-2 ${
                                            i.dis > 0 ? "" : "d-none"
                                          }`}
                                        >
                                          {i.dis} ₼
                                        </span>
                                      </p>
                                      <button
                                        className="pro-btn"
                                        onClick={() => {
                                          addItem(i.id);
                                          toast.success(
                                            `${t.cart + ":"} ${i.name}-${
                                              i.volume + "ML"
                                            }`,
                                            {
                                              duration: 700,
                                              style: {
                                                backgroundColor: "#00C2CB",
                                                color: "#ffffff",
                                                fontFamily: "Montserrat",
                                                border: "none",
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        <i className="icon-basket" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* product-list End */}
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                  {/* second tab-pane */}
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row grid-view-list theme1">
                      {filteredData.length === 0 ? (
                        <h4 className="text-start">Məhsul tapılmadı</h4>
                      ) : (
                        filteredData.slice(0,itemsToShow).map((i) => {
                          const active = localWish.find(
                            (item) => item.id == i.id
                          );
                          const brandName = brand.find((item)=>item.id == i.brand)
                          return (
                            <div className="col-12 mb-30">
                              <div className="card product-card">
                                <div className="card-body">
                                  <div className="media flex-column flex-md-row">
                                    <div className="product-thumbnail position-relative">
                                      <p
                                        className={`badge badge-danger mt-2 top-center ${
                                          i.dis > 0 ? "d-block" : "d-none"
                                        }`}
                                      >
                                        {t.dis}
                                      </p>
                                      <span className="badge badge-danger top-right">
                                        {i.volume} {t.ml}
                                      </span>
                                      {/* <a href="single-product.html"> */}
                                      {i.img && i.img.length > 0 && (
                                        <>
                                          <NavLink
                                            to={`/${slugify(
                                              `${i.id}-${i.name}`
                                            ).toLowerCase()}`}
                                          >
                                            <img
                                              src={i.img[1]}
                                              alt="thumbnail"
                                              className="first-img img-fluid"
                                              style={{ width: "300px" }}
                                            />
                                            <img
                                              src={i.img[0]}
                                              alt="thumbnail"
                                              className="second-img img-fluid"
                                              style={{ width: "300px" }}
                                            />
                                          </NavLink>
                                        </>
                                      )}
                                      {/* </a> */}
                                      {/* product links */}
                                      <ul className="actions d-flex justify-content-center">
                                        <li>
                                          <a
                                            className={`action ${
                                              active ? "activeWish" : ""
                                            }`}
                                            onClick={() => {
                                              addWishlist(i.id);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <span
                                              data-bs-toggle="tooltip"
                                              data-placement="bottom"
                                              title="add to wishlist"
                                              className="icon-heart"
                                            ></span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="action"
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#quick-view"
                                            onClick={() => {
                                              setSelectedData(i);
                                            }}
                                          >
                                            <span
                                              data-bs-toggle="tooltip"
                                              data-placement="bottom"
                                              title="Quick view"
                                              className="icon-magnifier"
                                            />
                                          </a>
                                        </li>
                                      </ul>
                                      {/* product links end*/}
                                    </div>
                                    <div className="media-body ps-md-4">
                                      <div className="product-desc py-0 px-0">
                                        <h3 className="title">
                                          <Link
                                            to={`/${slugify(
                                              `${i.id}-${i.name}`
                                            ).toLowerCase()}`}
                                          >
                                          {brandName.bName} - {i.name}
                                          </Link>
                                        </h3>
                                        <div className="star-rating mb-10">
                                          <span className="ion-ios-star" />
                                          <span className="ion-ios-star" />
                                          <span className="ion-ios-star" />
                                          <span className="ion-ios-star" />
                                          <span className="ion-ios-star de-selected" />
                                        </div>
                                        <p className="product-price">
                                          <del
                                            className="del"
                                            style={{
                                              textDecoration: `${
                                                i.dis > 0
                                                  ? "line-through"
                                                  : "none"
                                              }`,
                                            }}
                                          >
                                            {i.price} ₼
                                          </del>
                                          <span
                                            className={`onsale ms-2 ${
                                              i.dis > 0 ? "" : "d-none"
                                            }`}
                                          >
                                            {i.dis} ₼
                                          </span>
                                        </p>
                                      </div>
                                      <ul className="product-list-des text-secondary">
                                        <p>
                                          {i && language === "az"
                                            ? i && i.description.slice(0, 160)
                                            : language === "en"
                                            ? i && i.descriptionEn.slice(0, 160)
                                            : i &&
                                              i.descriptionRu.slice(0, 160)}
                                          ...
                                        </p>
                                      </ul>
                                      <button
                                        className="btn btn-dark btn--xl"
                                        onClick={() => {
                                          addItem(i.id);
                                          toast.success(
                                            `${t.cart + ":"} ${i.name}-${
                                              i.volume + "ML"
                                            }`,
                                            {
                                              duration: 700,
                                              style: {
                                                backgroundColor: "#00C2CB",
                                                color: "#ffffff",
                                                fontFamily: "Montserrat",
                                                border: "none",
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        {t.addToCart}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* product-list End */}
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-lg-3 mb-30 order-lg-first ${
                  result || productId ? "d-none" : ""
                }`}
              >
                <div className="sidbar-widget mb-5">
                  <input
                    type="text"
                    id="searchTerm"
                    placeholder={`${t.search}`}
                    onChange={(e) => setSearchProduct(e.target.value)}
                  />
                  <h4 className="sub-title mt-3">{t.brand}</h4>
                  <div style={{ height: "200px", overflow: "scroll" }}>
                    {brand
                      .filter((i) =>
                        product.some((product) => product.brand === i.id)
                      )
                      .map((i) => {
                        const productCount = product.filter(
                          (product) => product.brand === i.id
                        ).length;
                        return (
                          <div
                            className="widget-check-box d-flex justify-content-between"
                            key={i.id}
                          >
                            <input
                              type="checkbox"
                              id={`brand-${i.id}`} // Unikal ID
                              onChange={() => handleCheckboxChange(i.id)}
                            />
                            <label htmlFor={`brand-${i.id}`}>{i.bName}</label>
                            <p>({productCount})</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="sidbar-widget mb-5">
                  <h4 className="sub-title">{t.accord1}</h4>
                  <div style={{ height: "200px", overflow: "scroll" }}>
                    {accordProductCounts
                      .filter((item) => item.productCount > 0)
                      .map((i) => {
                        return (
                          <div
                            className="widget-check-box d-flex justify-content-between"
                            key={i.id}
                          >
                            <input
                              type="checkbox"
                              id={`accord-${i.id}`} // Unikal ID
                              onChange={() => handleAccord(i.id)}
                            />
                            <label htmlFor={`accord-${i.id}`}>
                              {language == "az"
                                ? i.aName
                                : language == "en"
                                ? i.aNameEn
                                : i.aNameRu}
                            </label>
                            <p>({i.productCount})</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="sidbar-widget mb-5">
                  <h4 className="sub-title">{t.not}</h4>
                  <input
                    id="searchTerm"
                    type="text"
                    placeholder={`${t.search}`}
                    onChange={(e) => setSearch(e.target.value)}
                    className="my-2"
                  />
                  <div
                    style={{
                      height: filteredTags.length === 0 ? "0px" : "200px",
                      overflow: "scroll",
                    }}
                  >
                    {sortedTag
                      .filter((item) => item.productCount > 0)
                      .map((i) => {
                        return (
                          <div
                            className="widget-check-box d-flex justify-content-between align-items-center"
                            key={i.id}
                          >
                            <input
                              type="checkbox"
                              id={`tag-${i.id}`} // Unikal ID
                              onChange={() => handleTagChange(i.id)}
                            />
                            <label htmlFor={`tag-${i.id}`}>
                              {language == "az"
                                ? i.tName
                                : language == "en"
                                ? i.tNameEn
                                : i.tNameRu}
                            </label>
                            <p className="ms-2">({i.productCount})</p>
                          </div>
                        );
                      })}
                  </div>

                  <button onClick={() => handleCountIncrement()}>
                    <p style={{ fontSize: "medium" }}>
                      {filteredTags.length == 0 && t.notfound}
                    </p>
                  </button>
                </div>
                <aside className="left-sidebar theme1">
                  {/* search-filter start */}
                  <div className="search-filter">
                    <div className="sidbar-widget pt-0">
                      <h4 className="title">{t.category}</h4>
                    </div>
                  </div>
                  <ul id="offcanvas-menu2" className="blog-ctry-menu">
                    {uniqueSex.map((item) => {
                      let label;

                      // Etiketin belirlenmesi
                      if (item === "1") {
                        label = `${t.man}`;
                      } else if (item === "2") {
                        label = `${t.woman}`;
                      } else if (item === "3") {
                        label = `${t.unisex}`;
                      }

                      return (
                        <li
                          key={item}
                          onClick={() => handleSexClick(item)} // Tıklama işlemi
                          style={{
                            cursor: "pointer", // Tıklanabilir stil
                            color: selectedSex.includes(item)
                              ? "#00C2CB"
                              : "black", // Seçilen öğe için renk değişimi
                          }}
                        >
                          {label} {/* Seçili olan öğe etiketini göster */}
                        </li>
                      );
                    })}
                  </ul>

                  <div className="search-filter">
                    <div className="py-4">
                      <h6 className="sub-title">{t.price} :</h6>
                      <div className="d-flex justify-content-between mb-3">
                        <p>
                          {t.min} : {values[0]} ₼
                        </p>
                        <p>
                          {t.max} : {values[1]} ₼
                        </p>
                      </div>
                      <ReactSlider
                        className="rangeslider"
                        onChange={handleChange}
                        min={0}
                        max={maxValue}
                        value={values}
                        pearling
                        minDistance={10}
                      />
                    </div>
                    <form action="#">
                      <div className="sidbar-widget mt-10">
                        <h4 className="sub-title">{t.volume}</h4>
                        <select
                          name=""
                          id=""
                          style={{
                            width: "100%",
                            padding: "5px",
                            borderRadius: "5px",
                            border: "1px solid grey",
                          }}
                          onChange={(e) => setVolume(e.target.value)}
                          value={volume}
                        >
                          <option value="all">{t.all}</option>
                          {uniqueVolumes
                            .sort((a, b) => b - a)
                            .map((item) => (
                              <option value={item.volume}>
                                {item} {t.ml}
                              </option>
                            ))}
                        </select>
                      </div>
                    </form>
                  </div>
                </aside>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-9 offset-lg-3">
                <nav className="pagination-section mt-30 mb-30">
                  <div
                    className={`react_pagination d-flex justify-content-center align-items-center mt-5 ${
                      currentPageData.length === 0 ? "d-none" : ""
                    }`}
                  >
                    {itemsToShow < filteredData.length && (
                      <button
                        onClick={handleShowMore}
                        className="btn btn-primary rounded px-4 py-2"
                      >
                        <p>
                        {t.readMore}
                        </p>
                      </button>
                    )}
                  </div>
                  {/* <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="ion-chevron-left" />
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="ion-chevron-right" />
                      </a>
                    </li>
                  </ul> */}
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalView data={selectedData} />
    </>
  );
};

export default Product;
