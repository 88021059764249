import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HeaderTop from "../components/HeaderTop";
import Contact from "../pages/Contact";
import Footer from "../components/Footer";
import Checkout from "../pages/cart/Checkout";
import Home from "../components/Home/Home";
import About from "../pages/About";
import CartView from "../pages/cart/CartView";
import Product from "../pages/cart/Product";
import ProductDetails from "../pages/cart/ProductDetails";
import Wishlist from "../pages/cart/Wishlist";
import ScrollToTop from "../utils/ScrollToTop";
import anime from "../assets/img/perfume.gif";
import Accord from "../pages/cart/Accord";
import Tag from "../pages/cart/Tag";
import WhatSapp from "../pages/WhatSapp";
import HashLoader from "react-spinners/HashLoader";
const AppRouter = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1800);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{height:"100vh"}}>
          <HashLoader color="#00AFD5"/>
        </div>
      ) : (
        <BrowserRouter>
          <ScrollToTop />
          <HeaderTop />
          <WhatSapp/>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/perfume" element={<Product />}></Route>
            <Route path="/perfume/:productId" element={<Product />}></Route>
            <Route path="/accord/:accordId" element={<Accord />}></Route>
            <Route path="/tag/:tagId" element={<Tag />}></Route>
            <Route path="/:productName" element={<ProductDetails />}></Route>
            <Route path="/cart" element={<CartView />}></Route>
            <Route path="/wishlist" element={<Wishlist />}></Route>
            <Route path="/checkout" element={<Checkout />}></Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      )}
    </>
  );
};

export default AppRouter;
