import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "../../utils/BreadCrumb";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import axios from "axios";
import { ApiLinkContext } from "../../context/ApiLinkContext";
import birkart from "../../assets/img/birkart.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const Checkout = () => {
  const { ApiLink } = useContext(ApiLinkContext);

  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [product, setProduct] = useState([]);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [promoName] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const [successPromo, setSuccessPromo] = useState(null);

  const [radio, setRadio] = useState("address");
  const [select, setSelect] = useState("Anbar (Express)");
  const [address, setAddress] = useState("");

  const [payment, setPayment] = useState("nagd");
  const [selectMonth, setSelectMonth] = useState(null);
  const [fullPayment, setFullPayment] = useState(null);

  const localCart = JSON.parse(localStorage.getItem("cart")) || [];

  const [ids, setIds] = useState([]);
  const [productName, setProductName] = useState([]);
  const [prices, setPrices] = useState([]);

  const [kbLink, setKbLink] = useState("");

  const month = [{ id: 0, month: "3" }];
  const navigate = useNavigate();
  const handleRadioChange = (e) => {
    setRadio(e.target.value);
  };

  const handlePaymentChange = (e) => {
    setPayment(e.target.value);
  };

  const handleSelectMonth = (monthId) => {
    setSelectMonth(monthId);
  };

  useEffect(() => {
    axios
      .get(`${ApiLink}/products.php`)
      .then((res) => {
        const data = res.data;
        if (data && data.length > 0 && Array.isArray(data)) {
          const localCartIds = localCart.map((item) => item.id);
          const findData = data
            .filter((item) => localCartIds.includes(item.id))
            .map((item) => {
              const findLocal = localCart.find((i) => i.id == item.id);
              return {
                ...item,
                quantity: findLocal ? findLocal.quantity : 0,
              };
            });

          setProduct(findData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const totalPrice = product.reduce(
    (total, item) =>
      total + (item.dis > 0 ? item.dis : item.price) * item.quantity,
    0
  );

  useEffect(() => {
    const expandedIds = localCart.flatMap((item) =>
      Array(item.quantity).fill(item.id)
    );
    setIds(expandedIds);

    const names = product.map((i) => i.name);
    setProductName(names);
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("id", JSON.stringify(ids));
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("radio", radio == "address" ? address : select);
    formData.append("payment", fullPayment == "1" ? fullPayment : selectMonth);
    formData.append("promo", promoCode);

    formData.append(
      "total",
      promoCode.toLowerCase() == "2000"
        ? totalPrice - (totalPrice.toFixed(2) * 20) / 100
        : promoCode.toLowerCase() == "myshops".toLowerCase()
        ? totalPrice - (totalPrice.toFixed(2) * 10) / 100
        : totalPrice.toFixed(2)
    );

    if (payment == "nagd") {
      axios
        .post(`${ApiLink}/order.php`, formData)
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: t.orderSuccess,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            window.location.reload(); // səhifəni yeniləyir
            localStorage.removeItem("cart");
            navigate("/");
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`${ApiLink}/kb.php`, formData)
        .then((res) => {
          const kbLink = res.data;
          setKbLink(kbLink);
          if (kbLink) {
            window.location.href = kbLink; // Yeni sayfaya yönlendiriyoruz
          }
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: t.orderSuccess,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handlePromoCode = () => {
    if (
      "myshops".toLowerCase() === promoCode.toLowerCase() ||
      "2000" == promoCode.toLowerCase()
    ) {
      setSuccessPromo(true);
    } else {
      setSuccessPromo(false);
    }
  };

  return (
    <>
      <BreadCrumb title={`${t.order.toUpperCase()}`} />
      <div className="check-out-section pt-80 pb-80">
        <div className="container">
          <form onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-lg-7">
                <div className="billing-info-wrap">
                  <h3 className="title">{t.billing}</h3>

                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="billing-info mb-20px">
                        <label>
                          {t.firstName},{t.lastName}{" "}
                          <span
                            className="text-danger"
                            style={{ fontWeight: "bold" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          required
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="billing-info mb-20px">
                        <label>
                          {t.phone}{" "}
                          <span
                            className="text-danger"
                            style={{ fontWeight: "bold" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          required
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="billing-select mb-20px">
                        <label htmlFor="inputState" className="form-label">
                          {t.email}
                        </label>
                        <input
                          type="email"
                          name=""
                          id=""
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mt-4">
                      <div className="billing-info-radio mb-20px">
                        <label>{t.delivery}</label>
                        <div className="col-lg-12">
                          <label>{t.radio}</label>
                          <br />
                          <div className="d-flex justify-content-start align-items-center">
                            <input
                              id="store-radio"
                              type="radio"
                              name="radio"
                              onChange={handleRadioChange}
                              value="1"
                              checked={radio === "1"}
                              className="radio"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="store-radio"
                              className="ms-2"
                            >
                              {t.radio1}
                            </label>
                          </div>
                        </div>
                        {radio == "1" && (
                          <div className="col-lg-12">
                            <select
                              name=""
                              id=""
                              className="w-100 my-4 py-3 px-2"
                              value={select}
                              onChange={(e) => setSelect(e.target.value)}
                              style={{ border: "1px solid #e6e6e6" }}
                            >
                              <option value="Anbar (Express)">
                                {t.filial1}
                              </option>
                              <option value="MyShops Gənclik Mall filialı">
                                {t.filial2}
                              </option>
                              <option value="MyShops Crescent Mall filialı">
                                {t.filial3}
                              </option>
                              <option value="MyShops Bülbül filialı">
                                {t.filial4}
                              </option>
                              <option value="MyShops Sumqayıt filialı">
                                {t.filial5}
                              </option>
                            </select>
                            {select == "Anbar (Express)" ? (
                              <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                  <p>
                                    {t.address} : {t.addFilial}
                                  </p>
                                  <p className="my-2">
                                    {t.hours} : 09:00-18:00
                                  </p>
                                  <p>{t.express}</p>
                                </div>
                                <div className="selectIframe">
                                  <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1518.864468791311!2d49.8908797!3d40.4148553!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403062bb50fae293%3A0x2055af934a567416!2zWcSxbGTEsXo!5e0!3m2!1str!2saz!4v1732605887791!5m2!1str!2saz"
                                    width="auto"
                                    height="auto"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                  ></iframe>
                                </div>
                              </div>
                            ) : select == "MyShops Gənclik Mall filialı" ? (
                              <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                  <p>
                                    {t.address} : {t.addFilial1}
                                  </p>
                                  <p className="my-2">
                                    {t.hours} : 10:00-22:00
                                  </p>
                                  <p>{t.during}</p>
                                </div>
                                <div className="selectIframe">
                                  <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6076.8269639389555!2d49.8524633!3d40.39969!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307de7669a3861%3A0x8f8ec10485cb2ec4!2sMyShops%20G%C9%99nclik%20Mall!5e0!3m2!1sen!2saz!4v1732605636865!5m2!1sen!2saz"
                                    width="auto"
                                    height="auto"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                  ></iframe>
                                </div>
                              </div>
                            ) : select == "MyShops Crescent Mall filialı" ? (
                              <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                  <p>
                                    {t.address} : {t.addFilial2}
                                  </p>
                                  <p className="my-2">
                                    {t.hours} : 10:00-22:00
                                  </p>
                                  <p>{t.during}</p>
                                </div>
                                <div className="selectIframe">
                                  <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12158.277643685158!2d49.860359!3d40.374071!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307dc375775d57%3A0x39d9cc337e72979b!2sCrescent%20Mall!5e0!3m2!1saz!2saz!4v1732606334211!5m2!1saz!2saz"
                                    width="auto"
                                    height="auto"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                  ></iframe>
                                </div>
                              </div>
                            ) : select == "MyShops Bülbül filialı" ? (
                              <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                  <p>
                                    {t.address} : {t.addFilial3}
                                  </p>
                                  <p className="my-2">
                                    {t.hours} : 10:00-22:00
                                  </p>
                                  <p>{t.during}</p>
                                </div>
                                <div className="selectIframe">
                                  <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12157.902877366718!2d49.842987!3d40.376148!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307dce37f1639b%3A0x7d196cd1fa9d8862!2sMyshops.az!5e0!3m2!1sen!2saz!4v1732606609001!5m2!1sen!2saz"
                                    width="auto"
                                    height="auto"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                  ></iframe>
                                </div>
                              </div>
                            ) : select == "MyShops Sumqayıt filialı" ? (
                              <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                  <p>
                                    {t.address} : {t.addFilial4}
                                  </p>
                                  <p className="my-2">
                                    {t.hours} : 10:00-22:00
                                  </p>
                                  <p>{t.during}</p>
                                </div>
                                <div className="selectIframe">
                                  <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48488.92729264683!2d49.691482!3d40.573438!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403097b33746e1db%3A0x96a47ee9b1422247!2sMyShops!5e0!3m2!1str!2saz!4v1732606698887!5m2!1str!2saz"
                                    width="auto"
                                    height="auto"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                  ></iframe>
                                </div>
                              </div>
                            ) : (
                              "Ünvan qeyd olunmayıb"
                            )}
                          </div>
                        )}
                        <div className="col-lg-12 mb-4">
                          <div className="d-flex justify-content-start align-items-center">
                            <input
                              id="store-address"
                              type="radio"
                              name="radio"
                              onChange={handleRadioChange}
                              value="address"
                              checked={radio === "address"}
                              className="radio"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="store-address"
                              className="ms-2"
                            >
                              {t.radio2}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          {radio == "address" && (
                            <div className="col-lg-12 mt-3">
                              <div className="billing-info  ">
                                <label>
                                  {t.address}{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  type="text"
                                  value={address}
                                  name="address"
                                  placeholder={`${t.inputP}`}
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 mt-4 mt-lg-0">
                <div className="your-order-area">
                  <h3 className="title">{t.yourOrder}</h3>
                  <div className="your-order-wrap gray-bg-4">
                    <div className="your-order-product-info">
                      <div className="your-order-top">
                        <ul>
                          <li>{t.product1}</li>
                          <li>{t.price}</li>
                        </ul>
                      </div>
                      <div className="your-order-middle">
                        <ul>
                          {product &&
                            product.length > 0 &&
                            product.map((i) => (
                              <li>
                                <span className="order-middle-left">
                                  {i.name} {i.volume}
                                  {t.ml}{" "}
                                  <span style={{ fontWeight: "500" }}>x</span>{" "}
                                  {i.quantity}
                                </span>
                                <span className="order-price">
                                  {i.quantity * i.dis > 0 ? i.dis : i.price} ₼{" "}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <h6>{t.payment}</h6>
                      <input
                        type="radio"
                        name="payment" // Eyni qrup adı olmalı
                        id="cash"
                        value="nagd" // value yalnız "nagd" olacaq
                        checked={payment === "nagd"} // payment nəyə bərabərdirsə, ona uyğun olacaq
                        onChange={handlePaymentChange}
                        className="radio"
                      />
                      <label
                        htmlFor="cash"
                        className="ms-2 mt-3 mb-2"
                        style={{ cursor: "pointer" }}
                      >
                        {t.payment1}
                      </label>
                      <br />
                      <input
                        type="radio"
                        name="payment" // Eyni qrup adı olmalı
                        id="online"
                        value="online" // value yalnız "online" olacaq
                        checked={payment === "online"} // payment nəyə bərabərdirsə, ona uyğun olacaq
                        onChange={handlePaymentChange}
                        className="radio"
                      />
                      <label
                        htmlFor="online"
                        className="ms-2"
                        style={{ cursor: "pointer" }}
                      >
                        {t.payment2}
                      </label>
                      {payment === "online" && (
                        <>
                          <div
                            className={`text-center mt-2 p-2 btnPayment  ${
                              fullPayment === "1" && !selectMonth
                                ? "activeFull"
                                : ""
                            }`}
                            onClick={() => {
                              setFullPayment("1");
                              setSelectMonth("");
                              if (selectMonth) {
                                // Seçilmiş ayı sıfırlamaq
                                handleSelectMonth(null);
                              }
                            }}
                          >
                            <p>{t.payment3}</p>
                          </div>
                          <p className="ms-1 my-3">{t.payment4}</p>
                          <br />
                          <img
                            src={birkart}
                            alt=""
                            className="img-fluid mb-3 ms-2"
                            style={{ width: "100px" }}
                          />
                          <div className="d-flex">
                            {month.map((item) => {
                              return (
                                <div
                                  className={`mx-2 taksitBtn ${
                                    selectMonth == item.month
                                      ? "activeMonth"
                                      : ""
                                  }`}
                                  style={{
                                    padding: `${
                                      language == "az"
                                        ? "15px"
                                        : language == "en"
                                        ? "15px 13px"
                                        : "15px 10px"
                                    }`,
                                  }}
                                  onClick={() => {
                                    handleSelectMonth(item.month);
                                    if (fullPayment) {
                                      setFullPayment(null);
                                    }
                                  }}
                                >
                                  <p>
                                    {item.month} {t.payment5}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}

                      <div className="your-order-total">
                        {/* <p>PROMO KOD</p> */}
                        <div
                          className="promoCode"
                          style={{ position: "relative" }}
                        >
                          <input
                            type="text"
                            onChange={(e) =>
                              setPromoCode(e.target.value.toLowerCase())
                            }
                            placeholder="PROMO KOD"
                            maxLength={13}
                            className={`p-2 ${successPromo && "d-none"}`}
                            style={{
                              background: "transparent",
                              border: "none",
                              borderBottom: "1px solid grey",
                              paddingRight: "100px", // "Tetbiq etmek" için sağa boşluk bırakıyoruz
                              width: "50%", // Genişliği ayarlayarak sağa dayalı metni bırakıyoruz
                            }}
                          />
                          <p
                            className={`${successPromo && "d-none"}`}
                            style={{
                              position: "absolute",
                              left: "35%",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: "2",
                              margin: "0", // Marjinleri sıfırlıyoruz
                              padding: "0",
                              cursor: "pointer",
                            }}
                            onClick={handlePromoCode}
                          >
                            {t.promoClick}
                          </p>
                        </div>

                        <span
                          className="my-2 mb-3"
                          style={{
                            fontSize: "small",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {successPromo === null ? ( // successPromo boşsa
                            t.promoDis
                          ) : successPromo ? ( // successPromo varsa, geçerli promo kodu için "Promo kod tetbiq edildi" veya geçersiz kod için "Promo kod duzgun deyil"
                            <>
                              <div className="d-flex align-items-center">
                                <img
                                  src="https://cdn-icons-png.flaticon.com/512/148/148767.png"
                                  alt=""
                                  className="img-fluid"
                                  style={{
                                    width: "20px",
                                    objectFit: "contain",
                                  }}
                                />{" "}
                                <span className="ms-2">
                                  {t.promoSuccess}{" "}
                                  {promoCode.toLowerCase() == "2000"
                                    ? "(20%)"
                                    : "(10%)"}
                                </span>
                              </div>
                              {/* <p className="text-danger">
                                {t.promoErr1}
                              </p> */}
                            </>
                          ) : (
                            <span className="text-danger">{t.promoErr}</span>
                          )}
                        </span>
                        {successPromo && (
                          <>
                            <br />
                            <div className="d-flex justify-content-between mb-3">
                              <li className="order-total">{t.price}</li>
                              <li className="order-total">
                                {totalPrice.toFixed(2)} ₼
                              </li>
                            </div>
                            <div className="d-flex justify-content-between">
                              <li className="order-total">
                                {" "}
                                {t.dis.charAt(0).toUpperCase() +
                                  t.dis.slice(1).toLowerCase()}
                              </li>
                              {promoCode.toLowerCase() == "2000" ? (
                                <li className="order-total">20%</li>
                              ) : (
                                <li className="order-total">10%</li>
                              )}
                            </div>
                            <hr />
                          </>
                        )}
                        <ul className="mt-4">
                          <li className="order-total">{t.total}</li>
                          <li>
                            {successPromo ? (
                              <>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    color: "grey",
                                    textDecoration: "line-through",
                                    marginRight: "10px",
                                  }}
                                >
                                  {totalPrice.toFixed(2)} ₼
                                </span>
                                {promoCode.toLowerCase() == "2000" ? (
                                  <span>
                                    {totalPrice -
                                      (totalPrice.toFixed(2) * 20) / 100}{" "}
                                    ₼
                                  </span>
                                ) : (
                                  <span>
                                    {totalPrice -
                                      (totalPrice.toFixed(2) * 10) / 100}{" "}
                                    ₼
                                  </span>
                                )}
                              </>
                            ) : (
                              <span>{totalPrice.toFixed(2)} ₼</span>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="Place-order mt-25">
                    <button
                      type="submit"
                      className="btn btn--xl btn-block btn-primary"
                      href="#"
                    >
                      {t.lastOrder}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Checkout;
