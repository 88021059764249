import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import ApiLinkContext from "../../context/ApiLinkContext";
import axios from "axios";

const ModalCart = ({ isOpen, onToggle }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [cart, setCart] = useState([]);
  const {ApiLink} = useContext(ApiLinkContext)
  const [findData,setFindData] = useState([])
  const updateCart = () => {
    const localCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(localCart);
  };
  useEffect(() => {
    updateCart();
    window.addEventListener("cartUpdated", updateCart);
    return () => {
      window.removeEventListener("cartUpdated", updateCart);
    };
  }, []);


  useEffect(()=>{
    axios.get(`${ApiLink}/products.php`)
    .then((res)=>{
      const productData = res.data
      const findData = productData && productData.filter((item)=>{
       return cart.some((i)=>i.id == item.id)
      })
      setFindData(findData)
    })
    .catch((err)=>{
      console.log(err);
    })
  },[cart])
  const findQuantity = findData.map((item)=>{
    const quantity = cart.find((i)=>i.id == item.id)
    return {
      ...item,
      quantity:quantity && quantity.quantity
    }
  })
  const totalPrice = findQuantity.reduce((total, product) => {
    return total + (parseFloat(product.dis > 0 ? product.dis : product.price) * product.quantity);
}, 0);

  const handleRemoveItem = (index) => {
    const removeItem = findQuantity.filter((_, idx) => idx !== index);
    localStorage.setItem("cart", JSON.stringify(removeItem));
    window.dispatchEvent(new Event("cartUpdated"));
  };

  return (
    <>
      <div
        class="offcanvas-overlay"
        onClick={() => onToggle(false)}
        style={{ display: `${isOpen ? "block" : "none"}` }}
      ></div>
      <div
        id="offcanvas-cart"
        className={`offcanvas offcanvas-cart theme1 ${isOpen ? "show" : ""}`}
      >
        {findData.length > 0 ? (
          <div className="inner">
            <div className="head d-flex flex-wrap justify-content-between">
              <p className="title">{t.cart.toUpperCase()}</p>
              <button
                className="offcanvas-close"
                onClick={() => onToggle(false)}
              >
                ×
              </button>
            </div>
            <ul className="minicart-product-list">
              {findQuantity.map((i, index) => (
                <li>
                  <a href="#" className="image">
                  {
                    i.img && i.img.length > 0 && (
                      <img
                      src={i.img[0]}
                      alt="Cart product Image"
                      className="img-fluid"
                    />
                    )
                  }
                  </a>
                  <div className="content">
                    <a href="#" className="title">
                      {i.name}
                    </a>
                    <span className="quantity-price">
                      {i.quantity} x <span className="amount">{i.dis > 0 ? i.dis : i.price} ₼</span>
                    </span>
                    <a
                      href="#"
                      className="remove"
                      onClick={() => handleRemoveItem(index)}
                    >
                      ×
                    </a>
                  </div>
                </li>
              ))}
            </ul>
            <div className="sub-total d-flex flex-wrap justify-content-between">
              <strong>{t.total} :</strong>
              <p className="amount">{totalPrice.toFixed(2)} ₼</p>
            </div>
            <Link to="/cart"
              className="btn btn-secondary btn--lg d-block d-sm-inline-block me-sm-2"
              onClick={()=>onToggle(false)}
            >
              {t.cart}
            </Link>
            <NavLink to="/checkout" onClick={() => onToggle(false)}>
              <a
                
                className="btn btn-dark btn--lg d-block d-sm-inline-block mt-4 mt-sm-0"
              >
                {t.order}
              </a>
            </NavLink>
          </div>
        ) : (
          <div className="inner">
            <div className="head d-flex flex-wrap justify-content-between">
              <span className="title">{t.cart}</span>
              <button
                className="offcanvas-close"
                onClick={() => onToggle(false)}
              >
                ×
              </button>
            </div>
            <h5>{t.emptycart}</h5> 
            <Link
              to="/perfume"
              onClick={() => onToggle(false)}
              className="btn btn-secondary btn--lg d-block d-sm-inline-block mt-30"
            >
              {t.product}
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default ModalCart;
