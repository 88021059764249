import React, { useContext } from "react";
import Header from "./Header";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";

const HeaderTop = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const t = translations[language];
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };
  return (
    <>
    <div >
    <div className="header-top theme1 py-15" style={{background:"#0194a1"}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6 order-last order-sm-first">
              <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                <div className="social-network2">
                  <ul className="d-flex">
                    <li>
                      <a href="https://www.facebook.com/MyParfumes.azGroup/?_rdr" target="_blank">
                        <span className="icon-social-facebook" />
                      </a>
                    </li>
                    <li className="mr-0">
                      <a href="https://www.instagram.com/myperfume.az/?igsh=cGFuZ2JmbHZ4amxj" target="_blank">
                        <span className="icon-social-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="media static-media media1 ms-4 d-flex align-items-center ">
                  <div className="media-body">
                    <div className="phone">
                      <a href="https://wa.me/994508055511" target="_blank" className="text-white">
                        <i className="icon-call-out me-1" /> +994 50 805 55 11
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 d-flex justify-content-between flex-row-reverse">
              <nav className="navbar-top pb-2 pb-sm-0 position-relative">
                <ul className="d-flex justify-content-center justify-content-md-end align-items-center">
                  <select
                    name=""
                    id="selectLang"
                    value={language}
                    onChange={handleLanguageChange}
                  >
                    <option value="az">AZ</option>
                    <option value="en">EN</option>
                    <option value="ru">RU</option>
                  </select>
                </ul>
              </nav>
              <div className="media static-media d-none" id="mediaBody">
                  <div className="media-body">
                    <div className="phone">
                      <a href="tel:+994508055511" className="text-white">
                        <i className="icon-call-out me-1" /> +994 50 805 55 11
                      </a>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Header />
    </div>
    </>
  );
};

export default HeaderTop;
